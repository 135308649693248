<template>
	<div class="posts">
		<div class="headers">
			<div v-for="(item,index) in list" :key='index' :class="filg==index?'actvlist':''" class="helist" @click="switeh(index)">{{item.name}}</div>
		</div>
		<div class="content">
			<div v-for="(item,index) in lists.records" :key='index' class="content_a" v-if="isfors">
				<div class="cont_left">
					<img :src='item.contentImgs' v-if="item.contentImgs&&item.content" />
					<img src='@/assets/img/xie/moren.png' v-if="!item.contentImgs&&item.content&&!item.remarks" />
					<video :src='item.remarks' v-if="item.remarks" ></video>
					<div class="cont_cont">
						<div>{{item.content}}</div>
						<div style="margin: 14px 0;">{{item.createTime}}</div>
						<div style="color: #FF9F16;" v-if="item.authFlag=='TOBEAUDITED'">审核中</div>
						<div style="color: #FF9F16;" v-if="item.authFlag=='PASS'">已通过</div>
						<div style="color: #FF9F16;" v-if="item.authFlag=='REFUSE'">未通过</div>
						<div style="color: #FF9F16;" v-if="item.authFlag=='CANCEL'">已取消</div>
					</div>
				</div>
				<div class="cont_right" v-if="item.authFlag=='TOBEAUDITED'" @click="putlist(item)">
					<span>取消审核</span>
				</div>
				<div class="cont_right" v-else @click="detlist(item.id)">
					<img src="../../../assets/img/xie/shan.png" />
					<span>删除</span>
				</div>
			</div>
			<div v-if="!isfors" style="text-align: center;color: #818182;padding-top: 20px;">暂无数据</div>
			<div class="pagings">
				<el-pagination background layout="prev, pager, next" 
				:page-size='parsm.pageSize' 
				@current-change="CurrentChange" 
				:total="lists.total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import { getfourm,detfourm,putsfourm } from "@/request/xie";
	export default{
		data(){
			return{
				list:[
					{name:'全部'},
					{name:'已通过'},
					{name:'审核中'},
					{name:'未通过'}
				],
				filg:0,
				lists:[],
				isfors:true,
				parsm:{
					pageNumber:1,
					pageSize:5,
					type:1,
					authFlag:''
				}
			}
		},
		mounted() {
			this.getlist()
		},
		methods:{
			//获取我的帖子
			async getlist(){
				let res=await getfourm(this.parsm)
				//console.log(res)
				this.lists=res.result
				this.lists.records.forEach((item,index)=>{
					if(item.contentImg){
						let sk=item.contentImg.split(',')
						item.contentImgs=sk[0]
					}
				})
				if(this.lists.records.length>0){
					this.isfors=true
				}else{
					this.isfors=false
				}
			},
			//切换
			switeh(ind){
				this.filg=ind
				if(this.filg==0){
					this.parsm.authFlag=''
				}else if(this.filg==1){
					this.parsm.authFlag='PASS'
				}else if(this.filg==2){
					this.parsm.authFlag='TOBEAUDITED'
				}else{
					this.parsm.authFlag='REFUSE'
				}
				this.parsm.pageNumber=1
				this.getlist()
			},
			//页数改变时
			CurrentChange(page){
				this.parsm.pageNumber = page;
				this.getlist();
			},
			//删除
			detlist(id){
				this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					detfourm(id).then((res)=>{
						this.$message.success('删除成功')
						this.isLastPage()
						this.getlist()
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});          
				});
			},
			//取消审核
			async putlist(item){
				item.authFlag='CANCEL'
				let res=await putsfourm(item)
				this.$message.success('操作成功')
				//console.log(res)
				this.getlist()
			},
			//判断当前页是否是最后一页
			isLastPage() {
			  let lastPage = Math.ceil((this.lists.total - 1) / this.parsm.pageSize);
			  //console.log(lastPage)
			  if (this.parsm.pageNumber == lastPage || this.parsm.pageNumber > lastPage) {
			    this.parsm.pageNumber = lastPage;
			  }
			},
		}
	}
</script>

<style scoped lang="scss">
	.posts{
		padding-bottom: 38px;
		.headers{
			height: 63px;
			display: flex;
			background-color: #fff;
			padding: 0 50px 0 40px;
			line-height: 60px;
			.helist{
				color: #818181;
				font-size: 16px;
				margin-right: 20px;
				cursor: pointer;
			}
			.actvlist{
				color: #00A3E0;
				border-bottom: 2px solid #00A3E0;
			}
		}
		.content{
			margin-top: 8px;
			background-color: #fff;
			box-shadow: 0px 8px 7px 1px rgba(55, 55, 55, 0.01);
			padding-bottom: 20px;
			padding-top: 4px;
			box-sizing: border-box;
			.content_a{
				padding: 24px 50px 28px 43px;
				border-bottom: 1px solid #F3F3F3;
				display: flex;
				flex-flow: row;
				justify-content: space-between;
				align-items: center;
				.cont_left{
					display: flex;
					align-items: center;
					img,video{
						width: 104px;
						height: 104px;
						margin-right: 16px;
					}
					.cont_cont{
						font-size: 14px;
						color: #535353;
						width: 588px;
						div:first-child{
							overflow: hidden;
							text-overflow: ellipsis;
							display:-webkit-box; 
							-webkit-box-orient:vertical;
							-webkit-line-clamp:2;
						}
					}
				}
				.cont_right{
					cursor: pointer;
					width: 110px;
					height: 40px;
					border: 1px solid #00A3E0;
					border-radius: 3px;
					display: flex;
					flex-flow: row;
					justify-content: center;
					align-items: center;
					img{
						width: 18px;
						height: 18px;
						margin-right: 6px;
					}
					span{
						color: #00A3E0;
						font-size: 14px;
					}
				}
			}
		}
		.pagings{
			display: flex;
			flex-flow: row;
			justify-content: flex-end;
			margin-top: 50px;
		}
	}
</style>
